import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { components } from "../../../api/schema";
import { HAS_GPU_SUPPORT } from "../../../utils/developmentFeatureFlags";
import { ScaleOpsProduct } from "../../../utils/typesUtils";
import { OverviewTableColumns } from "../BulkPolicyEditorToolbar";
import { OverviewTableFields } from "../overviewUtils";
import { getWithSubHeader, SubHeaderType } from "../utils";
import ActiveSavingsColumn from "./ActiveSavingsColumn";
import AutomatedColumn, { AutomatedColumnProps } from "./AutomatedColumn";
import CheckboxColumn, { CheckboxColumnHeader, CheckboxColumnProps } from "./CheckboxColumn";
import CpuRequestColumn from "./CpuRequestColumn";
import GpuRequestColumn from "./GpuRequestColumn";
import IssuesColumn from "./IssuesColumn";
import MemoryDiffColumn from "./MemoryDiffColumn";
import MinReplicasDiffColumn from "./MinReplicasDiffColumn";
import OriginalCpuRequestColumn from "./OriginalCpuRequestColumn";
import OriginalMemoryRequestColumn from "./OriginalMemoryRequestColumn";
import PolicyNameColumn, { PolicyNameColumnProps } from "./PolicyNameColumn";
import ReadyReplicasColumn from "./ReadyReplicasColumn";
import ReplicasColumn from "./ReplicasColumn";
import SavingsAvailableColumn from "./SavingsAvailableColumn";
import TotalCostsColumn from "./TotalCostsColumn";
import UnevictableColumn from "./UnevictableColumn";
import WorkloadsColumn, { WorkloadsColumnProps } from "./WorkloadsColumn";

type ColumnsProps = WorkloadsColumnProps &
  PolicyNameColumnProps &
  AutomatedColumnProps &
  CheckboxColumnProps & {
    selectedColumns: (string | undefined)[];
    allRows: components["schemas"]["UtilsWorkload"][];
  } & {
    scaleOpsProduct: ScaleOpsProduct | undefined;
  };

export default function useColumns(columnProps: ColumnsProps) {
  const selectedColumns = columnProps.selectedColumns;

  return [
    {
      field: "__check__",
      width: 50,
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => <CheckboxColumnHeader {...columnProps} />,
      renderCell: (
        params: GridRenderCellParams<
          components["schemas"]["DashIssues"],
          components["schemas"]["UtilsWorkload"],
          components["schemas"]["DashIssues"]
        >
      ) => <CheckboxColumn params={params} {...columnProps} />,
    },
    {
      field: OverviewTableFields.Issues,
      hide: true,
      headerName: "",
      width: 50,
      type: "string",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      getApplyQuickFilterFn: undefined,
      renderCell: (
        params: GridRenderCellParams<
          components["schemas"]["DashIssues"],
          components["schemas"]["UtilsWorkload"],
          components["schemas"]["DashIssues"]
        >
      ) => <IssuesColumn params={params} />,
    },
    {
      field: OverviewTableFields.Workload,
      headerName: "Workload",
      flex: 3,
      minWidth: 150,
      type: "string",
      align: "left",
      headerAlign: "center",
      cellClassName: "workloadCellContent",
      disableColumnMenu: true,
      sortable: true,
      renderCell: (
        params: GridRenderCellParams<
          components["schemas"]["DashIssues"],
          components["schemas"]["UtilsWorkload"],
          components["schemas"]["DashIssues"]
        >
      ) => <WorkloadsColumn params={params} {...columnProps} />,
    },
    {
      field: OverviewTableFields.TotalCost,
      headerName: "Total Cost",
      renderHeader: (params) => getWithSubHeader(SubHeaderType.Monthly, params.colDef.headerName),
      hide: !selectedColumns.includes(OverviewTableColumns.TotalCost),
      flex: 1.4,
      minWidth: 100,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "fullCellTooltipWrapper",
      disableColumnMenu: true,
      sortable: true,
      getApplyQuickFilterFn: undefined,
      renderCell: (params: GridRenderCellParams<number, components["schemas"]["UtilsWorkload"], string>) => (
        <TotalCostsColumn params={params} />
      ),
    },
    {
      field: OverviewTableFields.SavingsAvailable,
      headerName: "Savings Available",
      sortComparator: (a, b) => {
        if (a === -Infinity) return NaN;
        return a - b;
      },
      renderHeader: (params) => getWithSubHeader(SubHeaderType.Monthly, params.colDef.headerName),
      hide: !selectedColumns.includes(OverviewTableColumns.SavingsAvailable),
      flex: 1.3,
      minWidth: 122,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "fullCellTooltipWrapper",
      disableColumnMenu: true,
      sortable: true,
      getApplyQuickFilterFn: undefined,
      renderCell: (params: GridRenderCellParams<number, components["schemas"]["UtilsWorkload"], string>) => (
        <SavingsAvailableColumn params={params} {...columnProps} />
      ),
    },
    {
      field: OverviewTableFields.ActiveSavings,
      headerName: "Active Savings",
      renderHeader: (params) => getWithSubHeader(SubHeaderType.Monthly, params.colDef.headerName),
      hide: !selectedColumns.includes(OverviewTableColumns.ActiveSavings),
      flex: 1.4,
      minWidth: 130,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "fullCellTooltipWrapper",
      disableColumnMenu: true,
      sortable: true,
      getApplyQuickFilterFn: undefined,
      renderCell: (params: GridRenderCellParams<number, components["schemas"]["UtilsWorkload"], string>) => (
        <ActiveSavingsColumn params={params} {...columnProps} />
      ),
    },
    {
      field: OverviewTableFields.CPUDiff,
      headerName: "CPU Request",
      hide: !selectedColumns.includes(OverviewTableColumns.CpuRequests),
      flex: 1.4,
      minWidth: 133,
      type: "number",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: true,
      getApplyQuickFilterFn: undefined,
      cellClassName: "fullCellTooltipWrapper",
      renderCell: (params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>) => (
        <CpuRequestColumn params={params} />
      ),
    },
    {
      field: OverviewTableFields.OriginalCPURequest,
      headerName: "Original CPU Request",
      hide: !selectedColumns.includes(OverviewTableColumns.OriginalCpuRequests),
      flex: 1.5,
      minWidth: 150,
      type: "number",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: true,
      getApplyQuickFilterFn: undefined,
      cellClassName: "fullCellTooltipWrapper",
      renderCell: (params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>) => (
        <OriginalCpuRequestColumn params={params} />
      ),
    },
    {
      field: OverviewTableFields.MemoryDiff,
      headerName: "Memory Request",
      hide: !selectedColumns.includes(OverviewTableColumns.MemoryRequests),
      flex: 1.8,
      minWidth: 140,
      type: "number",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: true,
      getApplyQuickFilterFn: undefined,
      cellClassName: "fullCellTooltipWrapper",
      renderCell: (params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>) => (
        <MemoryDiffColumn params={params} />
      ),
    },
    {
      field: OverviewTableFields.OriginalMemoryRequest,
      headerName: "Original Memory Request",
      hide: !selectedColumns.includes(OverviewTableColumns.OriginalMemoryRequests),
      flex: 1.5,
      minWidth: 150,
      type: "number",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: true,
      getApplyQuickFilterFn: undefined,
      cellClassName: "fullCellTooltipWrapper",
      renderCell: (params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>) => (
        <OriginalMemoryRequestColumn params={params} />
      ),
    },
    {
      field: OverviewTableFields.OriginalGPURequest,
      headerName: "GPU Request",
      hide: !HAS_GPU_SUPPORT || !selectedColumns.includes(OverviewTableColumns.GpuRequests),
      flex: 0.7,
      minWidth: 50,
      type: "number",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: true,
      getApplyQuickFilterFn: undefined,
      cellClassName: "fullCellTooltipWrapper",
      renderCell: (params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>) => (
        <GpuRequestColumn params={params} />
      ),
    },
    {
      field: OverviewTableFields.Unevictable,
      headerName: "Unevictable",
      hide: !selectedColumns.includes(OverviewTableColumns.Unevcitable),
      flex: 1.4,
      minWidth: 50,
      type: "number",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: true,
      getApplyQuickFilterFn: undefined,
      cellClassName: "fullCellTooltipWrapper",
      renderCell: (params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>) => (
        <UnevictableColumn params={params} />
      ),
    },
    {
      field: OverviewTableFields.Replicas,
      minWidth: 120,
      sortable: true,
      headerName: "Replicas",
      hide: !selectedColumns.includes(OverviewTableColumns.Replicas),
      flex: 1.1,
      type: "number",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      getApplyQuickFilterFn: undefined,
      cellClassName: "fullCellTooltipWrapper",
      renderHeader: (params) => getWithSubHeader(SubHeaderType.RunningDesired, params.colDef.headerName),
      renderCell: (params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>) => (
        <ReplicasColumn params={params} {...columnProps} />
      ),
    },
    {
      field: OverviewTableFields.MinReplicasDiff,
      headerName: "Min Replicas",
      hide: !selectedColumns.includes(OverviewTableColumns.MinReplicasDiff),
      flex: 1,
      minWidth: 101,
      type: "number",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: true,
      getApplyQuickFilterFn: undefined,
      cellClassName: "fullCellTooltipWrapper",
      renderCell: (params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>) => (
        <MinReplicasDiffColumn params={params} {...columnProps} />
      ),
    },
    {
      field: OverviewTableFields.ReadyReplicas,
      minWidth: 128,
      sortable: true,
      headerName: "Ready Replicas",
      renderHeader: (params) => getWithSubHeader(SubHeaderType.ReadyDesired, params.colDef.headerName),
      hide: !selectedColumns.includes(OverviewTableColumns.ReadyReplicas),
      flex: 1.2,
      type: "number",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      getApplyQuickFilterFn: undefined,
      cellClassName: "fullCellTooltipWrapper",
      renderCell: (params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>) => (
        <ReadyReplicasColumn params={params} />
      ),
    },
    {
      field: OverviewTableFields.PolicyName,
      sortable: true,
      headerName: "Policy",
      cellClassName: "removePadding",
      hide: !selectedColumns.includes(OverviewTableColumns.Policy),
      flex: 1.9,
      minWidth: 240,
      valueOptions: columnProps.policyNames,
      editable: false,
      align: "left",
      headerAlign: "center",
      disableColumnMenu: true,
      getApplyQuickFilterFn: undefined,
      renderCell: (params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>) => (
        <PolicyNameColumn params={params} {...columnProps} />
      ),
    },
    {
      field: OverviewTableFields.Automated,
      headerName: "Automated",
      width: 127,
      type: "boolean",
      cellClassName: "border-r border-strongBorder removePadding",
      align: "center",
      headerAlign: "center",
      headerClassName: "workloadCellContent",
      disableColumnMenu: true,
      sortable: true,
      getApplyQuickFilterFn: undefined,
      renderCell: (params: GridRenderCellParams<boolean, components["schemas"]["UtilsWorkload"], boolean>) => (
        <AutomatedColumn params={params} {...columnProps} />
      ),
    },
  ] as GridColDef[];
}
