import { useQuery } from "@tanstack/react-query";
import { GetCappedStatusesResponse, GetCappedStatuses } from "../../../api/fetcher";
import useWorkloadsFilters from "../../../pages/Cost/Tables/Workloads/hooks/useWorkloadsFilters";
import { components } from "../../../api/schema";

export type CappedStatus = components["schemas"]["Capped_statusesCappedStatusFilterValue"];

const CappedSourceToDescription: Record<string, string> = {
  policyBoundary: "Policy boundary",
  costReductionPolicy: "Cost reduction policy",
  keepLimitPolicy: "Keep limit policy",
  keepRequestPolicy: "Keep request policy",
  setLimitPolicy: "Set limit policy",
  noLimitPolicy: "No limit policy",
  equalsToRequestPolicy: "Equals to request policy",
  integerCpuPolicy: "Integer cpu policy",
  hpa: "HPA",
  nodes: "Nodes",
  LimitRange: "Limit range",
  request: "Request",
};

export const cappedStatusToDescription = (status: CappedStatus): string => {
  const base = CappedSourceToDescription[status.source ?? ""] ?? "Unknown";
  if (status.type === "min") {
    return `${base} (minimum ${status.resource} capping)`;
  } else if (status.type === "max") {
    return `${base} (maximum ${status.resource} capping)`;
  }

  return "Unknown";
};

export const serializeCappedStatus = (status: CappedStatus): string => {
  return JSON.stringify(status);
};

export default function usedGetCappedStatuses() {
  const workloadFilters = useWorkloadsFilters();
  const { queryKey, queryFn } = GetCappedStatuses();

  return useQuery<GetCappedStatusesResponse, Error>({
    queryKey: [queryKey, workloadFilters],
    queryFn: () => queryFn({}),
  });
}
