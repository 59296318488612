import { Typography } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridRenderCellParams,
  GridRowParams,
  GridSortModel,
} from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import numeral from "numeral";
import { useEffect, useState } from "react";
import { ArrayParam, BooleanParam, StringParam, useQueryParam, withDefault } from "use-query-params";
import {
  GetAggregatedOverview,
  GetAggregatedOverviewResponse,
  GetAggregationWorkloads,
  GetAggregationWorkloadsResponse,
  LogicalOperator,
} from "../../api/fetcher";
import { convertArrayToStringArr } from "../../utils/arrayUtils";
import { getDataGridSx, HEADER_HEIGHT, ROW_HEIGHT } from "../../utils/styleUtils";
import PercentageMeter from "../PercentageMeter";
import Tooltip from "../Tooltip";
import { OVERVIEW_TAB_QUERY_KEY, OverviewTableTabOptions } from "../WorkloadStatusByNamespace/overviewUtils";
import useClearWorkloadsOverviewFilters, {
  NAMESPACES_QUERY_KEY,
} from "../WorkloadStatusByNamespace/useClearWorkloadsOverviewFilters";
import useHpaOptimizationEnabled from "../WorkloadStatusByNamespace/useHpaOptimizationEnabled";
import { AggregationColumnsFilter } from "./AggregationColumnsFilter";
import { AggregationExport } from "./AggregationExport";
import AggregationFilters from "./AggregationFilters";
import {
  AGGREGATION_ANNOTATION_QUERY_KEY,
  AGGREGATION_LABEL_QUERY_KEY,
  AGGREGATION_NAMESPACES_QUERY_KEY,
  AGGREGATION_WORKLOAD_DATA,
  ALL_WORKLOADS_STRING,
  ColumnNames,
  Columns,
  CPURequestCell,
  CurrencyValueCell,
  getAggregationParams,
  getAggregationValuesAsString,
  getAggregationValuesAsTooltipContent,
  MemoryRequestCell,
  NumericValueCell,
  ProvisionersValueCell,
  ROW,
} from "./utils";

import { enableFilterByUrlParam, FilterByUrlParam } from "../../utils/queryParams";
import { ScaleOpsProduct } from "../../utils/typesUtils";
import { getWithSubHeader, SubHeaderType } from "../WorkloadStatusByNamespace/utils";

const PAGE_SIZE = 25;

const NUMERAL_FORMAT = "0,0a";

const { queryKey: aggregatedOverviewQueryKey, queryFn: aggregatedOverviewQueryFn } = GetAggregatedOverview();
const { queryKey: aggregationWorkloadsQueryKey, queryFn: aggregationWorkloadsQueryFn } = GetAggregationWorkloads();

const columns: GridColDef[] = [
  {
    field: Columns.Name,
    headerName: ColumnNames[Columns.Name],
    flex: 3,
    minWidth: 200,
    type: "string",
    align: "left",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (params: GridRenderCellParams<string, ROW>) => {
      const aggregationParams = getAggregationParams(params.row.name);
      const rowName = getAggregationValuesAsString(aggregationParams);
      const tooltipContent = getAggregationValuesAsTooltipContent(aggregationParams);

      return (
        <Tooltip title={tooltipContent} maxWidth={500} className="w-full" disabled={rowName === ALL_WORKLOADS_STRING}>
          <div className="w-full truncate">{rowName}</div>
        </Tooltip>
      );
    },
  },
  {
    field: Columns.TotalCost,
    headerName: ColumnNames[Columns.TotalCost],
    flex: 1.5,
    minWidth: 150,
    type: "string",
    align: "center",
    disableColumnMenu: true,
    sortable: true,
    renderHeader: (params) => getWithSubHeader(SubHeaderType.Monthly, params.colDef.headerName),
    renderCell: CurrencyValueCell,
  },
  {
    field: Columns.SavingsAvailable,
    headerName: ColumnNames[Columns.SavingsAvailable],
    flex: 1.5,
    minWidth: 150,
    type: "string",
    align: "center",
    disableColumnMenu: true,
    sortable: true,
    renderHeader: (params) => getWithSubHeader(SubHeaderType.Monthly, params.colDef.headerName),
    renderCell: NumericValueCell,
  },
  {
    field: Columns.ActiveSavings,
    headerName: ColumnNames[Columns.ActiveSavings],
    flex: 1.5,
    minWidth: 150,
    type: "string",
    align: "center",
    disableColumnMenu: true,
    sortable: true,
    renderHeader: (params) => getWithSubHeader(SubHeaderType.Monthly, params.colDef.headerName),
    renderCell: NumericValueCell,
  },
  {
    field: Columns.CpuDiff,
    headerName: ColumnNames[Columns.CpuDiff],
    flex: 1.5,
    minWidth: 150,
    type: "string",
    align: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: CPURequestCell,
  },
  {
    field: Columns.MemDiff,
    headerName: ColumnNames[Columns.MemDiff],
    flex: 2,
    minWidth: 150,
    type: "string",
    align: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: MemoryRequestCell,
  },
  {
    field: Columns.AutomationPercentage,
    headerName: ColumnNames[Columns.AutomationPercentage],
    flex: 1.5,
    minWidth: 150,
    type: "string",
    align: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (params: GridRenderCellParams<number, ROW>) => {
      const percentageValue =
        params.value && !Number.isNaN(params.value) && params.value > 0 ? Math.round(params.value) : 0;

      const automatedWorkloads =
        !params.row.automated || Number.isNaN(params.row.automated) || params.row.automated < 0
          ? 0
          : numeral(params.row.automated).format(NUMERAL_FORMAT);
      const memberWorkloads =
        !params.row.members || Number.isNaN(params.row.members) || params.row.members < 0
          ? 0
          : numeral(params.row.members).format(NUMERAL_FORMAT);

      return (
        <Tooltip
          title={
            <>
              <b>{percentageValue}%</b> of workloads are <b>automated</b>
            </>
          }
          maxWidth={500}
          className="w-full flex flex-col justify-center items-center"
          disabled={memberWorkloads === 0}
        >
          <div className="w-full flex justify-center">
            <PercentageMeter percentage={percentageValue} width={148} />
          </div>
          <Typography variant="body2" sx={{ marginLeft: "10px" }}>
            {automatedWorkloads} of {memberWorkloads}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    field: Columns.OverProvisioned,
    headerName: ColumnNames[Columns.OverProvisioned],
    flex: 1.5,
    minWidth: 150,
    type: "string",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: ProvisionersValueCell,
  },
  {
    field: Columns.UnderProvisioned,
    headerName: ColumnNames[Columns.UnderProvisioned],
    flex: 1.5,
    minWidth: 150,
    type: "string",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: ProvisionersValueCell,
  },
];

const ENABLE_HPA_RECOMMENDATION = enableFilterByUrlParam(FilterByUrlParam.ENABLE_HPA_RECOMMENDATION);

interface Props {
  scaleOpsProduct: ScaleOpsProduct | undefined;
}

const WorkloadsAggregation = ({ scaleOpsProduct }: Props) => {
  const clearWorkloadsOverviewFilters = useClearWorkloadsOverviewFilters({});
  const [rows, setRows] = useState<ROW[]>([]);
  const [sortModel, setSortModel] = useState<GridSortModel | undefined>([
    {
      field: Columns.SavingsAvailable,
      sort: "desc",
    },
  ]);

  const [namespacesParams] = useQueryParam(AGGREGATION_NAMESPACES_QUERY_KEY, withDefault(BooleanParam, true));
  const [aggregatedLabels] = useQueryParam(AGGREGATION_LABEL_QUERY_KEY, ArrayParam);
  const [aggregatedAnnotations] = useQueryParam(AGGREGATION_ANNOTATION_QUERY_KEY, ArrayParam);
  const [, setLabels] = useQueryParam("labels", ArrayParam);
  const [, setAnnotations] = useQueryParam("annotations", ArrayParam);
  const [, setLabelLogicalOperator] = useQueryParam("logicalLabel", StringParam);
  const [, annotationLogicalOperator] = useQueryParam("logicalAnnotation", StringParam);
  const [, setSelectedTab] = useQueryParam(OVERVIEW_TAB_QUERY_KEY, StringParam);
  const [, setNamespacesArrParams] = useQueryParam(NAMESPACES_QUERY_KEY, ArrayParam);
  const [columnsVisibility, setColumnsVisibility] = useState<GridColumnVisibilityModel>({});
  const hpaOptimizationEnabled = useHpaOptimizationEnabled();

  const {
    data: aggregatedOverviewData,
    isError: aggregatedOverviewIsError,
    isLoading: aggregatedOverviewIsLoading,
    error: aggregatedOverviewError,
  } = useQuery<GetAggregatedOverviewResponse, Error>({
    queryKey: [aggregatedOverviewQueryKey, namespacesParams, aggregatedLabels, aggregatedAnnotations, scaleOpsProduct],
    queryFn: () =>
      aggregatedOverviewQueryFn({
        groupByNamespace: !!namespacesParams,
        groupByLabels: convertArrayToStringArr(aggregatedLabels),
        groupByAnnotations: convertArrayToStringArr(aggregatedAnnotations),
        // scaleOpsProduct
      }),
  });

  const { data: aggregationWorkloadsData } = useQuery<
    GetAggregationWorkloadsResponse,
    Error,
    AGGREGATION_WORKLOAD_DATA
  >({
    queryKey: [aggregationWorkloadsQueryKey, namespacesParams, aggregatedLabels, aggregatedAnnotations],
    queryFn: () =>
      aggregationWorkloadsQueryFn({
        groupByNamespace: !!namespacesParams,
        groupByLabels: convertArrayToStringArr(aggregatedLabels),
        groupByAnnotations: convertArrayToStringArr(aggregatedAnnotations),
      }),
  });

  useEffect(() => {
    if (aggregatedOverviewData) {
      const rows = aggregatedOverviewData.groups?.map((group) => {
        return {
          id: String(group.name),
          ...group,
          activeSavings:
            ENABLE_HPA_RECOMMENDATION || hpaOptimizationEnabled
              ? group.activeSavingsWithReplicas ?? group.activeSavings
              : group.activeSavings,
          cpuRecommended:
            ENABLE_HPA_RECOMMENDATION || hpaOptimizationEnabled
              ? group.cpuRecommendedWithReplicas ?? group.cpuRecommended
              : group.cpuRecommended,
          memRecommended:
            ENABLE_HPA_RECOMMENDATION || hpaOptimizationEnabled
              ? group.memRecommendedWithReplicas ?? group.memRecommended
              : group.memRecommended,
          savingsAvailable:
            ENABLE_HPA_RECOMMENDATION || hpaOptimizationEnabled
              ? group.savingsAvailableWithReplicas ?? group.savingsAvailable
              : group.savingsAvailable,
        };
      });
      setRows(rows ?? []);
    }
  }, [aggregatedOverviewData]);

  if (aggregatedOverviewIsError) {
    console.log("Error fetching aggregated overview", aggregatedOverviewError);
  }

  return (
    <div className="bg-white px-[31px] py-[28px] flex flex-col gap-[22px]">
      <div className="flex justify-between">
        <AggregationFilters />
        <AggregationColumnsFilter setColumnsVisibility={setColumnsVisibility} />
      </div>

      <DataGrid
        pagination
        autoHeight
        headerHeight={HEADER_HEIGHT}
        rowHeight={ROW_HEIGHT}
        sx={getDataGridSx()}
        columns={columns}
        columnVisibilityModel={columnsVisibility}
        initialState={{
          pagination: {
            pageSize: PAGE_SIZE,
          },
        }}
        rows={rows}
        loading={aggregatedOverviewIsLoading}
        sortModel={sortModel}
        onSortModelChange={(newSortModel: GridSortModel) => {
          setSortModel(newSortModel);
        }}
        rowCount={rows ? rows.length : 0}
        disableSelectionOnClick
        onRowClick={(params: GridRowParams<ROW>) => {
          const jsonObj = JSON.parse(params.row.name ?? "") as {
            namespace?: string;
            labels?: Record<string, string>[];
            annotations?: Record<string, string>[];
          };

          const namespace = jsonObj?.["namespace"];
          const labels = jsonObj?.["labels"]?.map((label) => {
            return `${label.key}=${label.value}`;
          });
          const annotations = jsonObj?.["annotations"]?.map((annotation) => {
            return `${annotation.key}=${annotation.value}`;
          });

          clearWorkloadsOverviewFilters();

          setNamespacesArrParams(namespace ? [namespace] : []);
          setLabels(labels ?? []);
          setAnnotations(annotations ?? []);

          setLabelLogicalOperator(LogicalOperator.AND);
          annotationLogicalOperator(LogicalOperator.AND);

          setSelectedTab(OverviewTableTabOptions.Workloads);
        }}
        getRowClassName={() => {
          return "cursor-pointer";
        }}
      />
      <AggregationExport aggregatedRows={rows} workloads={aggregationWorkloadsData?.workloads} />
    </div>
  );
};

export default WorkloadsAggregation;
