import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { UseMutationResult } from "@tanstack/react-query";
import * as Yup from "yup";
import { DeleteHPAPolicyParams, HPAPolicy } from "../../api/fetcher";
import { components } from "../../api/schema";
import UsedByWorkloads from "../../components/UsedByWorkloads";
import { ScaleOpsProduct } from "../../utils/typesUtils";
import PolicyActionCell from "../Policies/PolicyActionCell";
import PolicyName from "../Policies/PolicyName";
import { PoliciesStats } from "../Policies/usePoliciesStats";

const ADMIN_ROLE = "Admin";
export const DEFAULT_POLICY_NAME = "production";
export const POLICY_ROW_HEIGHT = 114;

export const policyNameValidation = (existingPolicyNames: string[]) =>
  Yup.string()
    .required("Required")
    .min(2, "Must be at least 2 characters")
    .matches(/^[a-zA-Z0-9-]+$/, "Invalid characters")
    .matches(/^[a-z0-9-]+$/, "Only lower case letters are allowed")
    .matches(/[a-zA-Z]{2}/, "Must include at least 2 letters")
    .test("unique", "Name already exists", (value) => {
      return !existingPolicyNames.includes(value);
    });

export const defaultHPAPolicyValues = {
  lookAheadDuration: "15m",
  headroomPercentage: 10,
  minAllowed: 1,
  generalWorkloadsWindow: "336h",
  generalWorkloadsPercentile: 100,
  predictableWorkloadsWindow: "336h",
  predictableWorkloadsPercentile: 80,
  requiredWindowCoverageDuration: "96h",
};

export type Row = {
  id: number;
  name: string;
  isBuiltIn: boolean | undefined;
  rowPolicyData: components["schemas"]["V1alpha1HpaPolicy"];
};

export enum HPAMutationType {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
}

interface GetColumnsParams {
  policiesStats: PoliciesStats;
  isReadyOnlyFrontEnd: boolean;
  userRole: string | undefined;
  deleteHPAPolicy: UseMutationResult<Record<string, never>, Error, DeleteHPAPolicyParams, unknown>;
  setSelectedPolicyToDuplicate: React.Dispatch<React.SetStateAction<HPAPolicy | undefined>>;
  setSelectedPolicyToEdit: React.Dispatch<React.SetStateAction<HPAPolicy | undefined>>;
}

export const getColumns = ({
  policiesStats,
  isReadyOnlyFrontEnd,
  userRole,
  deleteHPAPolicy,
  setSelectedPolicyToDuplicate,
  setSelectedPolicyToEdit,
}: GetColumnsParams): GridColDef[] => [
  {
    field: "name",
    headerName: "Policy name",
    flex: 2,
    renderCell: (params: GridRenderCellParams<string, Row, string>) => <PolicyName policyName={params.row.name} />,
  },
  {
    field: "usedByWorkloads",
    headerName: "Used by workloads",
    width: 400,
    sortable: false,
    headerAlign: isReadyOnlyFrontEnd ? "center" : undefined,
    renderCell: (params: GridRenderCellParams<string, Row, string>) => {
      const policyName = params.row.name;
      const usageCount = policiesStats.usageCount?.[policyName] ?? 0;
      const usagePercentage = policiesStats.usagePercentage?.[policyName] ?? 0;
      const potentialCount = policiesStats.potentialCount?.[policyName] ?? 0;
      const potentialPercentage = policiesStats.potentialPercentage?.[policyName] ?? 0;
      const totalWorkloadCount = policiesStats.totalWorkloadsCount ?? 0;

      return (
        <UsedByWorkloads
          policyName={policyName}
          usageCount={usageCount}
          usagePercentage={usagePercentage}
          potentialCount={potentialCount}
          potentialPercentage={potentialPercentage}
          totalWorkloadCount={totalWorkloadCount}
          totalWorkloadsCount={totalWorkloadCount}
          scaleOpsProduct={ScaleOpsProduct.HPA}
        />
      );
    },
  },
  {
    field: "actions",
    hide: isReadyOnlyFrontEnd,
    headerName: "Actions",
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams<string, Row, string>) => {
      const numberOfWorkloadsUsingPolicy = Number(policiesStats.usageCount?.[params.row.name]) || 0;
      const isPolicyUsed = numberOfWorkloadsUsingPolicy > 0;

      return (
        <PolicyActionCell
          onEditClickNew={() => {
            setSelectedPolicyToEdit(params.row?.rowPolicyData);
          }}
          onEditClick={() => {
            return;
          }}
          handleDelete={() => {
            deleteHPAPolicy.mutate({ name: params.row.name });
          }}
          handleDuplicateClick={() => {
            setSelectedPolicyToDuplicate(params.row?.rowPolicyData);
          }}
          isCustomizedPolicy={!params.row?.isBuiltIn}
          isDeletablePolicy={!isPolicyUsed}
          actionsDisabled={userRole !== ADMIN_ROLE}
          isHibernate={false}
        />
      );
    },
  },
];
