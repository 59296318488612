import Box from "@mui/material/Box";
import { Router as RemixRouter } from "@remix-run/router/dist/router";
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import * as React from "react";
import { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryParamProvider, StringParam, useQueryParam } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import "./App.tsx.css";
import ErrorPage from "./ErrorPage";
import { MainContextProvider, useMainContext } from "./MainContext";
import MainMenu, { MAIN_MENU_WIDTH } from "./MainMenu/MainMenu";
import { MAIN_MENU_ID_FOR_SCROLL_INITIALIZATION } from "./MainMenu/MainMenuItem";
import { WorkloadsContextProvider } from "./WorkloadsContext";
import { GetClustersResponse, getClusters } from "./api/fetcher";
import { Copyright } from "./components/CopyRight";
import DefaultFallback from "./components/DefaultFallback";
import DocumentHead from "./components/DocumentHead";
import HPAView from "./components/HPAView";
import LoginPage from "./components/LoginPage";
import PrivateRoute from "./components/PrivateRoute";
import WorkloadsDashboard from "./components/WorkloadsDashboard";
import AuditView from "./components/audits/Audit";
import Nodes from "./components/autoscalers/Autoscaler";
import MultiClusters from "./components/multiClusters";
import ReportsView from "./components/reports/ReportsContainer";
import Settings from "./components/settings";
import Unevictable from "./components/unevictable/Unevictable";
import { generateMock } from "./mock/mock";
import AnalyticsContainer, { HAS_MULTILEVEL_ANALYTICS } from "./pages/Analytics/AnalyticsV2/AnalyticsContainer";
import CostContainer from "./pages/Cost/CostContainer";
import { CostReportLinks, CostReportType } from "./pages/Cost/utils";
import HPAPoliciesView from "./pages/HPAPolicies/HPAPoliciesView";
import Alerts from "./pages/Notifications/Alerts";
import AlertsContainer from "./pages/Notifications/AlertsContainer";
import PoliciesView from "./pages/Policies/policy/PoliciesView";
import { HAS_GPU_SUPPORT, HAS_MULTI_PRODUCT_LAYOUT } from "./utils/developmentFeatureFlags";
import { ScaleOpsProduct } from "./utils/typesUtils";

const clustersApi = getClusters();

const bases = document.getElementsByTagName("base");
const hrefAttribute = bases[0].getAttribute("href");
const base = bases.length > 0 && hrefAttribute && hrefAttribute.length > 0 ? hrefAttribute : "/";
sessionStorage.setItem("baseName", base);

const HAS_NEW_ALERTS = true;
const CONTENT_PADDING = 20;

generateMock(false);

const MAIN_PRODUCT_LINKS = HAS_MULTI_PRODUCT_LAYOUT
  ? [
      {
        index: true,
        element: (
          <DocumentHead title="Rightsize | Workloads">
            <WorkloadsDashboard scaleOpsProduct={ScaleOpsProduct.Rightsize} />
          </DocumentHead>
        ),
      },
      {
        path: "/rightsize/policies",
        element: (
          <DocumentHead title="Rightsize | Policies">
            <PoliciesView />
          </DocumentHead>
        ),
      },
      {
        path: "/hpa/workloads",
        element: (
          <DocumentHead title="HPA | Workloads">
            <WorkloadsDashboard scaleOpsProduct={ScaleOpsProduct.HPA} />
          </DocumentHead>
        ),
      },
      {
        path: "/hpa/policies",
        element: (
          <DocumentHead title="HPA | Policies">
            <HPAPoliciesView />
          </DocumentHead>
        ),
      },
    ]
  : [
      {
        index: true,
        path: "",
        element: (
          <DocumentHead title="Workloads">
            <WorkloadsDashboard />
          </DocumentHead>
        ),
      },
      {
        path: "policy",
        element: (
          <DocumentHead title="Policies">
            <PoliciesView />
          </DocumentHead>
        ),
      },
    ];

const ANALYTICS_LINKS = HAS_MULTILEVEL_ANALYTICS
  ? [
      {
        path: "dashboards/analytics/general",
        element: <AnalyticsContainer hasGraphsContainer />,
      },
      {
        path: "dashboards/analytics/insight",
        element: <AnalyticsContainer hasTopKContainer />,
      },
      {
        path: "multiCluster/analytics/general",
        element: <AnalyticsContainer isMultiCluster hasGraphsContainer />,
      },
      {
        path: "multiCluster/analytics/insight",
        element: <AnalyticsContainer isMultiCluster hasTopKContainer />,
      },
    ]
  : [
      {
        path: "dashboards/analytics",
        element: (
          <DocumentHead title="Analytics">
            <AnalyticsContainer hasGraphsContainer hasAnalyticsView />
          </DocumentHead>
        ),
      },
      {
        path: "dashboards/workloads",
        element: (
          <DocumentHead title="Savings result Workloads">
            <ReportsView />
          </DocumentHead>
        ), // savings result
      },
      {
        path: "dashboards/insight",
        element: (
          <DocumentHead title="Insights">
            <AnalyticsContainer hasTopKContainer />
          </DocumentHead>
        ),
      },
      {
        path: "multiCluster/analytics",
        element: (
          <DocumentHead title="Clusters Analytics">
            <AnalyticsContainer isMultiCluster hasGraphsContainer />
          </DocumentHead>
        ),
      },
      {
        path: "multiCluster/insight",
        element: (
          <DocumentHead title="Clusters Insights">
            <AnalyticsContainer isMultiCluster hasTopKContainer />
          </DocumentHead>
        ),
      },
      {
        path: "multiCluster/dashboards/workloads",
        element: <ReportsView isMultiCluster={true} />, // savings result for multicluster
      },
      {
        path: "multiCluster/dashboards/analytics",
        element: <AnalyticsContainer isMultiCluster hasGraphsContainer hasAnalyticsView />,
      },
    ];

const GPU_LINKS = HAS_GPU_SUPPORT
  ? [
      {
        path: CostReportLinks.Gpu,
        element: (
          <DocumentHead title="GPU Cost Report">
            <CostContainer reportType={CostReportType.Gpu} />
          </DocumentHead>
        ),
      },
    ]
  : [];

function DashboardContent() {
  const [clusterId, setClusterId] = useQueryParam(CLUSTER_ID, StringParam);
  const [currentClusterURLParam, setCurrentClusterURLParam] = useQueryParam("currentClusterURLParam", StringParam);
  const { currentCluster, setCurrentCluster } = useMainContext();

  const { data: clustersData } = useQuery<GetClustersResponse, Error>({
    queryKey: [clustersApi.queryKey],
    queryFn: clustersApi.queryFn,
    refetchInterval: 10 * 1000,
    enabled: !clusterId,
  });

  useEffect(() => {
    if (clustersData) {
      const parentCluster = clustersData?.clusters?.find((cluster) => cluster.isParent);
      const firstCluster = clustersData?.clusters?.[0];
      const clusterToSet = parentCluster ?? firstCluster;

      switch (true) {
        case !currentClusterURLParam && currentCluster !== clusterToSet?.name:
          setCurrentClusterURLParam(clusterToSet?.name);
          setCurrentCluster(clusterToSet?.name);
          break;
        case currentClusterURLParam && currentCluster !== currentClusterURLParam:
          setCurrentCluster(currentClusterURLParam ?? undefined);
          break;
        default:
          break;
      }
    }
  }, [clustersData, currentClusterURLParam]);

  useEffect(() => {
    if (clusterId) {
      sessionStorage.setItem(CLUSTER_ID, clusterId);
      setClusterId(undefined);
    }
  }, [clusterId]);
  const ref = React.useRef<HTMLDivElement>(null);
  const { mainComponentScrollTop, setMainComponentScrollTop } = useMainContext();

  useEffect(() => {
    if (ref.current && mainComponentScrollTop) {
      ref.current.scrollTop = mainComponentScrollTop;
    }
  }, []);

  useEffect(() => {
    if (ref.current) {
      const handleScroll = () => {
        if (ref.current) {
          setMainComponentScrollTop(ref.current.scrollTop);
        }
      };

      ref.current.addEventListener("scroll", handleScroll);
      return () => {
        setMainComponentScrollTop(0);
        if (ref.current) {
          ref.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [ref]);

  if (!currentCluster) return null;

  return (
    <>
      <MainMenu />
      <Box sx={{ display: "flex" }}>
        <Box
          component="main"
          sx={{
            backgroundColor: "#F7F7F7",
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
          ref={ref}
          id={MAIN_MENU_ID_FOR_SCROLL_INITIALIZATION}
        >
          <div
            style={{
              paddingLeft: MAIN_MENU_WIDTH + CONTENT_PADDING,
              paddingRight: CONTENT_PADDING,
              paddingTop: CONTENT_PADDING,
            }}
          >
            <Outlet />
            <Copyright sx={{ pt: 4 }} />
          </div>
        </Box>
      </Box>
    </>
  );
}

const queryClient: QueryClient = new QueryClient();

const CLUSTER_ID = "clusterId";
export default function App() {
  const router: RemixRouter = createBrowserRouter(
    [
      {
        path: "/",
        errorElement: <ErrorPage />,
        element: (
          <QueryParamProvider adapter={ReactRouter6Adapter} options={{ enableBatching: true }}>
            <MainContextProvider>
              <WorkloadsContextProvider>
                <PrivateRoute>
                  <div className="min-w-[1300px]">
                    <DashboardContent />
                  </div>
                </PrivateRoute>
              </WorkloadsContextProvider>
            </MainContextProvider>
          </QueryParamProvider>
        ),
        children: [
          ...MAIN_PRODUCT_LINKS,
          {
            path: "unevictable",
            element: (
              <DocumentHead title="Unevictable">
                <Unevictable />
              </DocumentHead>
            ),
          },
          {
            path: "hpa",
            element: (
              <DocumentHead title="HPA">
                <HPAView />
              </DocumentHead>
            ),
          },
          {
            path: "nodes",
            element: (
              <DocumentHead title="Nodes">
                <Nodes />
              </DocumentHead>
            ),
          },
          {
            path: "multiCluster",
            element: (
              <DocumentHead title="Clusters">
                <ErrorBoundary fallback={<DefaultFallback message="An error occurred while opening Multi Cluster" />}>
                  <MultiClusters />
                </ErrorBoundary>
              </DocumentHead>
            ),
          },
          {
            path: "multiCluster/reports",
            element: (
              <DocumentHead title="Cluster Savings">
                <ReportsView hasTabs={false} isMultiCluster />
              </DocumentHead>
            ),
          },
          {
            path: "reports",
            element: (
              <DocumentHead title="Savings">
                <ReportsView />
              </DocumentHead>
            ),
          },
          {
            path: "audits",
            element: (
              <DocumentHead title="Audits">
                <AuditView />
              </DocumentHead>
            ),
          },
          {
            path: "settings",
            element: (
              <DocumentHead title="Settings">
                <Settings />
              </DocumentHead>
            ),
          },
          {
            path: "alerts",
            element: (
              <DocumentHead title="Alerts">
                {HAS_NEW_ALERTS ? <AlertsContainer isMultiCluster={false} /> : <Alerts />}
              </DocumentHead>
            ),
          },
          {
            path: "/multiCluster/alerts",
            element: (
              <DocumentHead title="Clusters Alerts">
                {HAS_NEW_ALERTS ? <AlertsContainer isMultiCluster={true} /> : <Alerts />}
              </DocumentHead>
            ),
          },
          {
            path: CostReportLinks.Compute,
            element: (
              <DocumentHead title="Compute Cost Report">
                <CostContainer reportType={CostReportType.Compute} />
              </DocumentHead>
            ),
          },
          {
            path: CostReportLinks.Network,
            element: (
              <DocumentHead title="Network Cost Report">
                <CostContainer reportType={CostReportType.Network} />
              </DocumentHead>
            ),
          },
          ...ANALYTICS_LINKS,
          ...GPU_LINKS,
        ],
      },
      {
        path: "/login",
        element: (
          <DocumentHead title="Login">
            <LoginPage />
          </DocumentHead>
        ),
      },
    ],
    {
      basename: base,
    }
  );

  useEffect(() => {
    const allNestedPathProps = [
      "",
      ...router.routes
        .flatMap((route) => route.children ?? [])
        .map((route) => route.path)
        .filter((entity) => entity && entity.length > 0),
    ] as string[];

    // Necessary for E2E tests
    console.log("routes:", allNestedPathProps);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ToastContainer />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
