import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import CheckedIcon from "../Icons/CheckedIcon";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import { getDataGridSx } from "../utils/styleUtils";
import { components } from "../api/schema";
import { adjustedDayjs } from "../utils/dateAndTimeUtils";
import { capitalizeFirstLetter, DEFAULT_DATE_TIME_FORMAT } from "../utils/formatterUtils";
import Tooltip from "./Tooltip";
import * as React from "react";
import EventsIcon from "../Icons/EventsIcon";

interface Props {
  events: { time?: Record<string, never>; message?: string }[];
  entityName: string;
  minHeight?: number;
  isLoading?: boolean;
}

const Events = ({ events, entityName, minHeight, isLoading }: Props) => {
  const rows = events
    ?.filter?.((event) => event?.time && event?.message)
    ?.sort((a, b) => ((a.time ?? 0) > (b.time ?? 0) ? -1 : 1))
    ?.map((event, index) => ({
      id: index,
      ...event,
    }));

  return (
    <div style={{ minHeight: minHeight }} className={"flex flex-col gap-5"}>
      <div className="flex border border-border rounded p-4 items-center gap-10">
        <EventsIcon width={40} height={40} />
        <Typography variant="body2">
          <b>{capitalizeFirstLetter(entityName)} Events</b>
          <p>Explore real time {entityName} events</p>
        </Typography>
      </div>
      {isLoading && (
        <div className="flex m-auto items-center justify-center h-full w-full">
          <CircularProgress />
        </div>
      )}
      {!isLoading && !rows?.length && (
        <div className="w-full h-[300px] flex items-center justify-center">
          <div className="flex flex-col items-center justify-center gap-2">
            <Typography>No events found</Typography>
            <CheckedIcon width={40} height={40} className="animate-pulse" />
          </div>
        </div>
      )}
      {!isLoading && !!rows?.length && (
        <div className="w-full h-full">
          <DataGrid
            rows={rows}
            sx={getDataGridSx()}
            columns={[
              {
                field: "lastTimestamp",
                headerName: "Time",
                width: 300,
                renderCell: (params: GridRenderCellParams<string, components["schemas"]["V1Event"]>) => {
                  const displayValue = adjustedDayjs(params.value).format(DEFAULT_DATE_TIME_FORMAT);
                  return (
                    <Typography variant="body2" className="w-full truncate">
                      {displayValue}
                    </Typography>
                  );
                },
              },
              {
                field: "message",
                headerName: "Details",
                flex: 1,
                renderCell: (params: GridRenderCellParams<string, components["schemas"]["V1Event"]>) => {
                  return (
                    <Tooltip title={params.value} className="w-full">
                      <Typography variant="body2" className="w-full truncate">
                        {params.value}
                      </Typography>
                    </Tooltip>
                  );
                },
              },
            ]}
            pageSize={10}
            rowsPerPageOptions={[10, 25, 50, 100]}
            autoHeight
            disableSelectionOnClick
          />
        </div>
      )}
    </div>
  );
};

export default Events;
